import { FC } from "react";

import useOutcomes from "@hooks/use-outcomes";
import { OutcomeType } from "@lib/data/schemas/outcome";
import { infoLabel } from "@lib/outcome";

import Label from "@components/Form/Label";
import SearchableMultiSelectForm from "@components/Form/SelectForm/SearchableMultiSelectForm";
import { CustomOptionType } from "@components/Form/SelectForm/types";
import LoadingSpinner from "@components/LoadingSpinner";
import OutcomeIcon from "@components/Outcomes/OutcomeIcon";

const CountingRuleStepField: FC = () => {
  const { outcomes } = useOutcomes();

  const getLabelAndValue = (outcome: OutcomeType): CustomOptionType => ({
    label: (
      <OutcomeIcon
        outcome={outcome}
        withLabel
        className="w-4 h-4"
        iconClassName="p-1"
        info={infoLabel(outcome)}
      />
    ),
    value: outcome.id,
  });

  const options = outcomes?.map((o) => getLabelAndValue(o));

  return (
    <>
      <Label>Session counting rule</Label>
      {options ? (
        <SearchableMultiSelectForm
          defaultValue={undefined}
          options={options}
          name="acceptedOutcomes"
          placeholder="All outcomes"
          checkBoxOption
          type="outcomes"
          controlClassNames="!bg-white"
          menuClassNames="!pb-2"
          emptyMessage="No outcomes found"
        />
      ) : (
        <LoadingSpinner variant="transparent" />
      )}
    </>
  );
};

export default CountingRuleStepField;
