import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";
import { isArray, pick } from "lodash";

import { SVGIconProps } from "@lib/shared-types";

import { IconSelector } from "@components/CustomFields/CustomFieldForm/IconSelector";
import { ErrorMessage } from "@components/ErrorMessage";
import { Tooltip } from "@components/Tooltips/Tooltip";

import { RateType } from "./utils";

interface CommonRateTypeFieldProps {
  className?: string;
  disabled?: boolean;
  disabledTitle?: boolean;
  disabledRate?: boolean;
  rightIcon?: FC<SVGIconProps>;
  autoFocus?: boolean;
  tooltipText?: string;
}

interface RateTypeFieldProps extends CommonRateTypeFieldProps {
  title?: RateType["title"];
  currency: string;
  onChangeTitle: (value: string) => void;
  icon?: RateType["icon"];
  onChangeIcon?: (value: string) => void;
  value?: RateType["value"];
  onChangeRate: (value: string) => void;
  onBlur?: (rateType: RateType) => void;
}

const RateTypeField: FC<RateTypeFieldProps> = ({
  className,
  title = "",
  value = "",
  icon = "ic_info",
  currency,
  onChangeTitle,
  onChangeRate,
  onChangeIcon,
  onBlur,
  disabled = false,
  disabledTitle = false,
  disabledRate = false,
  rightIcon: RightIcon,
  autoFocus = false,
  tooltipText,
}) => {
  const baseStyle = "rounded-md border border-grey-900 disabled:bg-grey-950";
  const disabledStyle = "bg-transparent text-grey-800";
  const isTitleDisabled = disabled || disabledTitle;
  const isRateDisabled = disabled || disabledRate;
  const rippleSyle =
    "focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-white focus:ring-opacity-50 focus:outline-none focus:border-action-700";

  const rightIcon = !!RightIcon && <RightIcon />;
  const rightElement = (
    <div className="absolute right-2">
      {tooltipText ? (
        <Tooltip trigger={rightIcon ?? icon}>{tooltipText}</Tooltip>
      ) : (
        rightIcon
      )}
    </div>
  );

  return (
    <div className={classNames("flex items-center gap-2 w-full", className)}>
      <div className="flex w-7/12">
        <div>
          <IconSelector
            iconSelectorClassNames="py-2 border-r-0 bg-white"
            value={icon}
            onChange={(val) => {
              onChangeIcon && onChangeIcon(val);
              onBlur?.({ title, value, icon: val });
            }}
          />
        </div>

        <input
          className={classNames(
            "px-4 py-2 placeholder:text-grey-800 truncate w-full",
            isTitleDisabled && disabledStyle,
            baseStyle,
            rippleSyle,
            "rounded-l-none"
          )}
          type="text"
          placeholder="Rate title"
          value={title}
          onChange={(e) => onChangeTitle(e.target.value)}
          onBlur={(e) => {
            const newTitle = e.target.value;
            if (newTitle) onBlur?.({ title: newTitle, value, icon });
          }}
          disabled={isTitleDisabled}
          autoFocus={autoFocus}
        />
      </div>
      <div
        className={classNames(
          "relative flex items-center w-5/12 bg-grey-950",
          baseStyle
        )}
      >
        <label
          className={classNames(
            "text-grey-500 border-r px-4 py-2",
            isRateDisabled && disabledStyle
          )}
        >
          {currency}/hr
        </label>
        <input
          className={classNames(
            "clear-appearence border-none w-full rounded-r-md placeholder:text-grey-800",
            isRateDisabled && disabledStyle,
            rippleSyle
          )}
          type="number"
          value={value}
          onChange={(e) => {
            const val = Number(e.target.value);
            onChangeRate(val);
          }}
          onBlur={(e) => {
            onBlur?.({
              title: title ?? "",
              value: Number(e.target.value),
              icon,
            });
          }}
          disabled={isRateDisabled}
        />
        {rightElement}
      </div>
    </div>
  );
};

interface RateTypeControlledFieldProps extends CommonRateTypeFieldProps {
  name: string;
  currency?: string;
  rightIcon?: FC<SVGIconProps>;
  onBlur?: (rateType: RateType) => void;
}

export const RateTypeControlledField: FC<RateTypeControlledFieldProps> = ({
  className,
  name,
  currency,
  rightIcon,
  disabled = false,
  disabledTitle = false,
  disabledRate = false,
  onBlur,
  autoFocus = false,
  tooltipText,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const watchCurrency = watch("currency");

  const getError = (name: string) => {
    const { rateTypes } = errors;
    if (!rateTypes || !isArray(rateTypes)) return;
    return rateTypes.find((rt) => rt?.ref?.name === name)?.message;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => {
          if (parseFloat(value.value) <= 0)
            return "Rate must be greater than 0";
          return true;
        },
      }}
      render={({ field }) => {
        const error = getError(name);
        return (
          <div className="flex flex-col gap-1">
            <RateTypeField
              className={className}
              currency={currency ?? watchCurrency}
              title={field.value?.title}
              onChangeTitle={(value) =>
                field.onChange({ ...field.value, title: value })
              }
              value={field.value?.value}
              onChangeRate={(value) =>
                field.onChange({ ...field.value, value })
              }
              icon={field.value?.icon}
              onChangeIcon={(value) =>
                field.onChange({ ...field.value, icon: value })
              }
              onBlur={
                error ||
                !Object.values(
                  pick(field.value, "title", "icon", "value")
                ).every(Boolean)
                  ? undefined
                  : onBlur
              }
              rightIcon={rightIcon}
              disabled={disabled}
              disabledTitle={disabledTitle}
              disabledRate={disabledRate}
              autoFocus={autoFocus}
              tooltipText={tooltipText}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </div>
        );
      }}
    />
  );
};
