import { ReactNode } from "react";

import { OutcomeType } from "@lib/data/schemas/outcome";

import InfoFilledIcon from "@components/Icons/InfoFilledIcon";
import { OutcomeIconInfo } from "@components/Outcomes/OutcomeIcon";

export const infoLabel = (outcome: OutcomeType): ReactNode | undefined => {
  let title: string | undefined;
  if (outcome.category === "cancelled_outside_window") {
    title = "Client cancels outside of appointment window";
  } else if (outcome.category === "cancelled_inside_window") {
    title = "Client cancels inside appointment window";
  }

  if (title) {
    return (
      <OutcomeIconInfo
        title={title}
        icon={<InfoFilledIcon className="h-4 w-4 text-action-500" />}
      />
    );
  }

  return undefined;
};
