import { FC, HTMLProps } from "react";
import classNames from "classnames";

import { AccessType } from "@lib/data/schemas/account";

import PermissionFullIcon from "@components/Icons/PermissionFullIcon";
import PermissionLimitedIcon from "@components/Icons/PermissionLimitedIcon";
import PermissionOwnerIcon from "@components/Icons/PermissionOwnerIcon";

export const permissionsData = {
  full: {
    title: "Admin",
    Icon: PermissionOwnerIcon,
  },
  elevated: {
    title: "Partner",
    Icon: PermissionFullIcon,
  },
  limited: {
    title: "Collaborator",
    Icon: PermissionLimitedIcon,
  },
};

export interface AccessPermissionProps extends HTMLProps<HTMLParagraphElement> {
  type: AccessType;
  isOwner: boolean;
}

const AccessPermission: FC<AccessPermissionProps> = ({
  type,
  isOwner,
  className,
  ...props
}) => {
  const permission = permissionsData[type];

  return (
    <p
      className={classNames(
        "flex items-center text-grey-500 text-sm",
        className
      )}
      {...props}
    >
      {isOwner ? (
        <span>Owner</span>
      ) : (
        <>
          <span>{permission.title}</span>
        </>
      )}
    </p>
  );
};

export default AccessPermission;
