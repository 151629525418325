import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PermissionOwnerIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path d="M4 13a8 8 0 1114.03 5.257.525.525 0 00.014.716l.707.707a.481.481 0 00.7-.01A9.963 9.963 0 0022 13c0-5.523-4.477-10-10-10S2 7.477 2 13c0 2.6.993 4.97 2.62 6.748a.481.481 0 00.696.007l.707-.707a.525.525 0 00.01-.72A7.97 7.97 0 014 13z"></path>
      <path
        fillRule="evenodd"
        d="M16 13a4 4 0 10-8 0 4 4 0 008 0zm-4-2a2 2 0 110 4 2 2 0 010-4z"
        clipRule="evenodd"
      ></path>
      <path d="M16 18a1 1 0 011 1v1H7v-1a1 1 0 011-1h8z"></path>
    </svg>
  );
};

export default PermissionOwnerIcon;
