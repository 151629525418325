import { FC, SyntheticEvent, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getStripeSubscriptionUpdatePreview } from "api-services/definitions/stripe";
import { useApiGet } from "api-services/endpoints";
import isEmpty from "lodash/isEmpty";
import { Stripe } from "stripe";

import { useAuth } from "@contexts/auth";
import useStripeInfo from "@hooks/use-stripe-info";
import { AccessType } from "@lib/data/schemas/account";
import { displayItemAmountString } from "@lib/products";

import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import { TodaysCharge } from "@components/DisplayDetails/ChargeInfo";
import TextFieldForm from "@components/Form/TextFieldForm";
import ArrowIcon from "@components/Icons/ChevronLeftIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import BigModal, { BigModalProps } from "@components/Modals/BigModal";
import { CardInfo } from "@components/Plans/SubscriptionCreditCard";
import { getFreeMembersSeats, getPricePerSeat } from "@components/Plans/utils";

import AboutAccess from "../AboutAccess";
import AccessPermissionFormField from "../AccessPermissionFormField";

export type FormData = {
  email?: string;
  accessPermission: AccessType;
};

enum VariantTypes {
  invite = "invite",
  change = "change",
}

type VariantKeys = keyof typeof VariantTypes;

type DataType = Record<
  VariantTypes,
  {
    title: string;
    description: string;
  }
>;

export interface MemberPermissionModalProps {
  show: BigModalProps["show"];
  toggleShow: BigModalProps["toggleShow"];
  variant?: VariantKeys;
  hasUnlimitedMembers?: boolean;
  onSubmit: (data: FormData) => Promise<void>;
}

export const data: DataType = {
  [VariantTypes.invite]: {
    title: "Who’s joining your team?",
    description:
      "Add a trusted user to your Practice account to assist with how you manage your business.",
  },
  [VariantTypes.change]: {
    title: "Change member permissions",
    description:
      "Change permissions for members of your team and control their access to your business.",
  },
};

export const permissionText = {
  full: "Admins can manage the organization similar to an owner account.",
  elevated:
    "Partners only have access to their client list and can modify items in your account.",
  limited:
    "Collaborators have restricted access and cannot create or update anything in your account.",
};

const MemberPermissionModal: FC<MemberPermissionModalProps> = ({
  show,
  toggleShow,
  variant = VariantTypes.invite,
  hasUnlimitedMembers,
  onSubmit,
}) => {
  const { oid, organization } = useAuth();
  const { subscription, membersSubscription } = useStripeInfo(oid);
  const [step, setStep] = useState(0);
  const isVariantInvite = variant === VariantTypes.invite;
  const isVariantChange = variant === VariantTypes.change;
  const totalMembersSubscription = organization?.memberAccountIds?.length ?? 0;
  const totalFreeMembersSeats = getFreeMembersSeats(
    membersSubscription as Stripe.Subscription
  );
  const shouldShowNextStep =
    hasUnlimitedMembers && totalMembersSubscription > totalFreeMembersSeats;
  const isFirstStep = step === 0;

  // With unlimited members, they can change permissions later on so we default to low privileges
  // When limited (to 1 extra seat), they can't change permissions so we default to full privileges
  const defaultPermission = hasUnlimitedMembers ? "limited" : "full";

  const methods = useForm<FormData>({
    mode: "onSubmit",
    defaultValues: {
      ...(isVariantInvite && { email: "" }),
      accessPermission: defaultPermission,
    },
  });

  const actionButtonText = useMemo(() => {
    return isVariantChange
      ? "Update permissions"
      : hasUnlimitedMembers
      ? isFirstStep && shouldShowNextStep
        ? "Continue with permissions"
        : "Confirmation invitation"
      : "Confirm invitation";
  }, [isVariantChange, hasUnlimitedMembers, isFirstStep, shouldShowNextStep]);

  const isYearly = membersSubscription?.plan?.metadata?.interval === "year";
  const isCustomPrice = membersSubscription?.plan?.metadata?.custom === "true";

  const { data: previewMemberSubscription } = useApiGet(
    getStripeSubscriptionUpdatePreview,
    { subscriptionId: membersSubscription?.id ?? "", userId: oid ?? "" },
    {
      customPrice: isCustomPrice.toString(),
      previewPriceId: membersSubscription?.plan.id,
    }
  );

  const {
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = methods;
  const watchAccessPermission = watch("accessPermission");

  const { title, description } = data[variant];

  const isActionDisabled = useMemo(
    () => isSubmitting || !isEmpty(errors),
    [isSubmitting, isValid, errors]
  );

  const handleCallSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (isFirstStep && shouldShowNextStep && isValid && !isVariantChange) {
      setStep(1);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const BackIcon = isFirstStep ? CloseIcon : ArrowIcon;

  const handleClickClose = () => {
    if (isFirstStep) {
      toggleShow && toggleShow(false);
    } else {
      setStep(0);
    }
  };

  const renderPaymentInfo = !isFirstStep && (
    <div className="bg-white rounded-lg border border-grey-900">
      {!!subscription && (
        <CardInfo
          className="border-b"
          label="Payment method"
          value={
            (typeof subscription?.default_payment_method === "string"
              ? subscription?.default_payment_method
              : subscription?.default_payment_method?.card?.last4) ?? ""
          }
          lastNumbers
        />
      )}
      {!!membersSubscription && (
        <CardInfo
          label="Billing"
          value={`${getPricePerSeat(
            membersSubscription as Stripe.Subscription
          )} / member / ${isYearly ? "year" : "month"}`}
        />
      )}
    </div>
  );

  const renderPaymentDue = !isFirstStep && previewMemberSubscription && (
    <TodaysCharge
      variant="internal"
      className="mb-8"
      total={
        displayItemAmountString(
          previewMemberSubscription.preview.amount,
          previewMemberSubscription.currency
        ) ?? ""
      }
      description={previewMemberSubscription.preview.description}
      isTrial={previewMemberSubscription.preview.isTrial}
    />
  );

  const renderInfoBanner = () => {
    if (!isFirstStep) {
      return <div className="flex-1" />;
    }

    const text = hasUnlimitedMembers
      ? permissionText[watchAccessPermission]
      : "While removing members is enabled at anytime, switching permissions isn’t supported.";

    return (
      <SmallBanner
        className="text-grey-500 mt-2"
        items={[
          {
            Icon: InfoIcon,
            text,
          },
        ]}
        variant="regular"
      />
    );
  };

  return (
    <BigModal
      show={show}
      toggleShow={toggleShow}
      size="bigger"
      contentClassName="md:max-h-none"
      disableClose
      hideHeader
    >
      <form
        className="grid sm:grid-flow-col sm:grid-cols-2 gap-8"
        onSubmit={handleCallSubmit}
      >
        <div className="flex flex-col space-y-4">
          <div className="">
            <Button
              className="w-8 h-8 !p-0 mb-8"
              onClick={handleClickClose}
              circle
            >
              <BackIcon className="w-6 h-6" />
            </Button>
            <h1 className="text-2xl text-black-ink mb-3">
              {isFirstStep ? title : "Almost there..."}
            </h1>
            {isFirstStep && <p className="text-gray-500">{description}</p>}
          </div>

          <FormProvider {...methods}>
            {isVariantInvite && isFirstStep && (
              <TextFieldForm
                containerClassName="mb-0"
                name="email"
                label="Invitee email"
                register={methods.register}
                errors={errors}
                errorMessage="Please enter a valid email address"
                isEmailErrorMessage="Please enter a valid email address"
                isEmail
                required
              />
            )}

            <div className="flex-1" />

            {hasUnlimitedMembers && isFirstStep && (
              <AccessPermissionFormField />
            )}

            {renderPaymentInfo}
            {renderPaymentDue}
            {renderInfoBanner()}

            <Button
              className="w-full mt-auto"
              variant="primary"
              type="submit"
              isLoading={isSubmitting}
              disabled={isActionDisabled}
            >
              {actionButtonText}
            </Button>
          </FormProvider>
        </div>

        <AboutAccess
          type={watchAccessPermission}
          hasUnlimitedMembers={hasUnlimitedMembers}
          showPreview={!isFirstStep}
          email={watch("email")}
        />
      </form>
    </BigModal>
  );
};

export default MemberPermissionModal;
