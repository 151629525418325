import { FC } from "react";

import useToggle from "@hooks/use-toggle";

import { Button } from "@components/Button";

import BillingCardSummaryWrapper from "./BillingCardSummaryWrapper";
import BillingPreferencesModal from "./BillingPreferencesModal";

interface EmptyBillingCardSummaryProps {
  className?: string;
}

const EmptyBillingCardSummary: FC<EmptyBillingCardSummaryProps> = ({
  className,
}) => {
  const { value: showModal, toggle: toggleModal } = useToggle();
  return (
    <>
      <BillingCardSummaryWrapper className={className}>
        <div className="flex items-center p-6">
          <div className="flex flex-col space-y-1">
            <h3 className="font-medium text-green-200">Accounts payable</h3>
            <p className="text-sm text-grey-500">
              Easily track how much is owed to each team member for their
              sessions.
            </p>
          </div>
          <div>
            <Button variant="green" onClick={toggleModal} small>
              Setup now
            </Button>
          </div>
        </div>
      </BillingCardSummaryWrapper>
      {showModal && (
        <BillingPreferencesModal
          show
          toggleShow={toggleModal}
          onClickClose={toggleModal}
        />
      )}
    </>
  );
};

export default EmptyBillingCardSummary;
