import { FC } from "react";

import { Button } from "@components/Button";
import ArrowIcon from "@components/Icons/ArrowIcon";
import CloseIcon from "@components/Icons/CloseIcon";

interface BillingPreferencesHeaderModalProps {
  step: number;
  onClickBack: () => void;
  isLastStep: boolean;
  title: string;
  description: string;
}

const BillingPreferencesHeaderModal: FC<BillingPreferencesHeaderModalProps> = ({
  step,
  onClickBack,
  isLastStep,
  title,
  description,
}) => {
  const Icon = step === 1 ? CloseIcon : ArrowIcon;

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-8">
        <Button onClick={onClickBack} icon={<Icon />} square circle smaller />
        {!isLastStep && (
          <p className="font-medium text-sm text-grey-500">{`Step ${step} / 3`}</p>
        )}
      </div>
      <h1 className="font-normal text-2xl text-black-ink mb-4">{title}</h1>
      <p className="font-normal text-grey-500">{description}</p>
    </div>
  );
};

export default BillingPreferencesHeaderModal;
