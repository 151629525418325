import classNames from "classnames";

import useTimezones from "@hooks/use-timezones";

import TimezoneIcon from "@components/Icons/TimezoneIcon";

import SelectForm from "./Form/SelectForm";

interface TimeZonePickerProps {
  control: any;
  name: string;
  hasIcon?: boolean;
  wrapperClassName?: string;
  label?: string;
}

const TimeZonePicker: React.FC<TimeZonePickerProps> = ({
  control,
  name,
  hasIcon = false,
  wrapperClassName,
  label = "Time zone",
}) => {
  const { timeZones } = useTimezones();

  return (
    <div className={classNames("mt-6 z-10", wrapperClassName)}>
      {hasIcon && (
        <div className="text-sm leading-5 text-grey-500">Time zone</div>
      )}
      <div className="flex items-end w-full">
        {hasIcon && (
          <div className="border bg-grey-950 py-2 px-3 rounded-l-lg -mr-1 z-20">
            <TimezoneIcon className="text-grey-500" />
          </div>
        )}
        <SelectForm
          containerClassName="w-full"
          name={name}
          label={label}
          control={control}
          options={timeZones}
          customSearchKey
        />
      </div>
    </div>
  );
};

export default TimeZonePicker;
