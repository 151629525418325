import React, { FC, ReactNode, useMemo } from "react";
import classNames from "classnames";
import compact from "lodash/compact";
import moment from "moment";

import { displayItemAmountString } from "@lib/products";
import { frequencyString } from "@lib/utils/subscription-plans";

import { Button } from "@components/Button";
import InfoIcon from "@components/Icons/InfoIcon";
import { Tooltip } from "@components/Tooltips/Tooltip";

import { CardInfoProps, SubscriptionCreditCardProps } from "../types";

const MembersInfo: FC = () => (
  <Tooltip
    className="inline-block text-grey-500 hover:text-grey-300 ml-auto my-auto mr-5"
    trigger={<InfoIcon className="w-6 h-6" />}
  >
    Add or remove members to adjust your monthly subscription
  </Tooltip>
);

const style = {
  line: "font-medium text-md flex items-center",
  border: "border-grey-900",
  lineContainer: "flex-1",
  label: "text-grey-500 mb-0.5 flex items-center",
  value: "text-black-ink",
  cancel: "text-peach-500 underline",
};

type NextInvoiceLineProps = {
  title: string;
  nextInvoiceIssueDate: string | Date;
  postContent?: ReactNode;
  frequency: string;
};

const NextInvoiceLine: FC<NextInvoiceLineProps> = ({
  title,
  nextInvoiceIssueDate,
  frequency,
  postContent,
}) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-[1fr_1fr_80px] items-center p-4 border-b",
        style.border
      )}
    >
      <div className={style.line}>
        <div className={style.lineContainer}>
          <h3 className={style.label}>{title}</h3>
          <p className={style.value}>{frequencyString[frequency]}</p>
        </div>
      </div>
      <div className={style.line}>
        <div className={style.lineContainer}>
          <h3 className={style.label}>Next invoice</h3>
          <p className={style.value}>
            {moment(nextInvoiceIssueDate).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>

      {postContent}
    </div>
  );
};

export const CardInfo: FC<CardInfoProps> = ({
  className,
  label,
  value,
  lastNumbers,
  onClickEditCard,
}) => {
  const isCardValid = value !== "not-found";
  const newValue = isCardValid ? value : "No credit card found";
  const displayValue = useMemo(
    () => compact([lastNumbers && isCardValid && "••••", newValue]).join(" "),
    [lastNumbers, newValue, isCardValid]
  );
  const buttonText = isCardValid ? "Edit" : "Add";

  return (
    <div className={classNames("p-4", style.line, className)}>
      <div className={style.lineContainer}>
        <h3 className={style.label}>{label}</h3>
        <p className={style.value}>{displayValue}</p>
      </div>
      {onClickEditCard && (
        <Button onClick={onClickEditCard} small>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

const SubscriptionCreditCard: FC<SubscriptionCreditCardProps> = ({
  className,
  canEdit,
  planSubscription,
  memberSubscription,
  lastNumbers,
  credits,
  // onClickCancel,
  onClickEditFrequency,
  onClickEditCard,
}) => {
  // const renderCancelButton = (
  //   <div className={style.line}>
  //     <Button
  //       className="!bg-transparent text-peach-500 underline pl-0"
  //       onClick={onClickCancel}
  //     >
  //       Cancel subscription
  //     </Button>
  //   </div>
  // );

  return (
    <div
      className={classNames(
        "bg-white rounded-lg border",
        style.border,
        className
      )}
    >
      {memberSubscription && (
        <NextInvoiceLine
          title="Member billing"
          {...memberSubscription}
          postContent={<MembersInfo />}
        />
      )}

      <NextInvoiceLine
        title="Plan billing"
        {...planSubscription}
        postContent={
          canEdit && (
            <Button className="ml-auto" onClick={onClickEditFrequency} small>
              Edit
            </Button>
          )
        }
      />

      {lastNumbers && (
        <CardInfo
          label="Credit card on file"
          value={lastNumbers}
          onClickEditCard={canEdit ? onClickEditCard : undefined}
          lastNumbers
        />
      )}

      {credits && !!credits.amount && (
        <div className={classNames("border-t p-4", style.line, style.border)}>
          <div className={style.lineContainer}>
            <h3 className={style.label}>Credit available</h3>
            <p className={style.value}>
              {displayItemAmountString(credits.amount, credits.currency)}
            </p>
          </div>
        </div>
      )}
      {/* TODO: Remove this and associated code if finalized decision is reached to not have cancel, temporarily disabling March 22, 2023 */}
      {/* {renderCancelButton} */}
    </div>
  );
};

export default SubscriptionCreditCard;
