import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PermissionFullIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_5260_38710)">
        <path
          fillRule="evenodd"
          d="M2 8a6 6 0 019.393-4.949.667.667 0 10.755-1.099 7.333 7.333 0 103.105 4.965.667.667 0 00-1.319.195A6 6 0 112 8zm11.776-2.835a.667.667 0 10-.886-.997L7.36 9.084 5.137 6.862a.667.667 0 10-.942.943l2.444 2.444a1 1 0 001.372.04l5.765-5.124z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5260_38710">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default PermissionFullIcon;
