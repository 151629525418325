import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { compact } from "lodash";

import useTimezones from "@hooks/use-timezones";

import {
  FormDataType,
  getFirstPayPeriodOptions,
  payPeriodOptions,
  payPeriodStartOptions,
} from "./utils";

interface GridItemProps {
  className?: string;
  label: string;
  value: string;
}

const GridItem: FC<GridItemProps> = ({ className, label, value }) => (
  <div className={classNames("px-4 py-2", className)}>
    <h3 className="font-normal text-sm text-grey-500">{label}</h3>
    <p className="font-medium text-black-ink">{value}</p>
  </div>
);

const FinalStep: FC = () => {
  const { getValues } = useFormContext<FormDataType>();
  const { timeZones } = useTimezones();

  const {
    currency,
    payPeriod,
    payPeriodStart,
    firstPayPeriod,
    rateTypes,
    timeZone: timeZoneValue,
  } = getValues();

  const timeZone =
    timeZones.find((item) => item.value === timeZoneValue)?.label ?? "";

  const rateTypesFormatted = useMemo(() => {
    const validRateTypes = compact(rateTypes);
    const rateTypesValues = validRateTypes.map(
      (rateType) => `${rateType.value}/h`
    );
    return rateTypesValues.join(" · ");
  }, [rateTypes]);

  const data = [
    { label: "Currency", value: currency, className: "border-r" },
    {
      label: "Pay period",
      value:
        payPeriodOptions.find((item) => item.value === payPeriod)?.label ?? "",
    },
    {
      label: "Pay period start",
      value:
        payPeriodStartOptions.find((item) => item.value === payPeriodStart)
          ?.label ?? "",
      className: "border-r",
    },
    {
      label: "First pay period",
      value:
        getFirstPayPeriodOptions(payPeriodStart).find(
          (item) => item.value === firstPayPeriod
        )?.label ?? "",
    },
    {
      label:
        "The accounts payable cutoff at midnight based of your preferred timezone",
      value: timeZone,
      className: "col-span-2",
    },
    {
      label: "Hourly rates",
      value: rateTypesFormatted,
      className: "col-span-2",
    },
  ];

  return (
    <div className="grid grid-cols-2 divide-grey-900 border rounded-lg">
      {data.map(({ className, ...item }) => (
        <GridItem
          key={item.label}
          className={classNames("border-b last:border-b-0", className)}
          {...item}
        />
      ))}
    </div>
  );
};

export default FinalStep;
