import { FC, ReactNode } from "react";
import classNames from "classnames";

interface BillingCardSummaryWrapperProps {
  className?: string;
  children: ReactNode;
}

const BillingCardSummaryWrapper: FC<BillingCardSummaryWrapperProps> = ({
  className,
  children,
}) => (
  <div
    className={classNames(
      "border border-grey-900 rounded-lg flex flex-col bg-gradient-to-b from-green-900/40 to-grey-950/40",
      className
    )}
  >
    {children}
  </div>
);

export default BillingCardSummaryWrapper;
