import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { CUSTOM_FIELD_ICONS } from "@lib/constants/customFieldIcons";

import Transition from "@components/Transition";

export interface IconSelectorProps {
  value: string;
  onChange: (icon: string) => void;
  iconSelectorClassNames?: string;
  iconModalClassnames?: string;
}

export function IconSelector({
  onChange,
  value,
  iconSelectorClassNames,
  iconModalClassnames,
}: IconSelectorProps) {
  const iconModalRef = useRef<HTMLDivElement | null>(null);
  const [isIconModalOpen, setIsIconModalOpen] = useState(false);

  useEffect(() => {
    const showInfo = (e: MouseEvent) => {
      if (!iconModalRef.current?.contains(e.target as Node))
        setIsIconModalOpen(false);
    };
    document.addEventListener("mouseup", (e) => showInfo(e));
    return () => {
      document.removeEventListener("mouseup", (e) => showInfo(e));
    };
  }, []);

  const getIcon = (icon: string) => {
    const Icon = CUSTOM_FIELD_ICONS[icon];
    return <Icon className="text-black" />;
  };

  return (
    <div
      className={classNames(
        "flex justify-center border rounded-l-lg hover:bg-grey-950 px-[1px] h-full w-[43px] cursor-pointer",
        iconSelectorClassNames
      )}
    >
      <div>
        <div
          ref={iconModalRef}
          className="flex items-center h-full text-sm font-medium"
          onClick={() => setIsIconModalOpen(true)}
        >
          {getIcon(value)}
        </div>
        <Transition show={isIconModalOpen} className={undefined}>
          <div
            className={classNames(
              "z-50 absolute bottom-8 left-0 md:-right-2 md:left-auto mt-8 shadow-equal-20 rounded-2xl overflow-hidden",
              iconModalClassnames
            )}
          >
            <div className="grid grid-cols-6 p-2 bg-white w-52 h-72">
              {Object.keys(CUSTOM_FIELD_ICONS).map((icon) => (
                <div
                  className={classNames(
                    "p-1 cursor-pointer hover:bg-action-600 rounded-lg transition duration-150 ease-in-out",
                    icon === value && "bg-action-600"
                  )}
                  key={icon}
                  onClick={() => {
                    onChange(icon);
                    setIsIconModalOpen(false);
                  }}
                >
                  {getIcon(icon)}
                </div>
              ))}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
