import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const MergeIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("stroke-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="6" r="2" strokeWidth="2" />
      <circle cx="19" cy="12" r="3" strokeWidth="2" />
      <circle cx="5" cy="18" r="2" strokeWidth="2" />
      <path
        d="M7 18H9C10.6569 18 12 16.6569 12 15V15C12 13.3431 13.3431 12 15 12L16 12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 6H9C10.6569 6 12 7.34315 12 9V9C12 10.6569 13.3431 12 15 12L16 12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MergeIcon;
