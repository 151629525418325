import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PermissionFullIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_5260_38722)">
        <path
          fillRule="evenodd"
          d="M2.036 7.333a5.96 5.96 0 01.587-2h10.753c.305.614.508 1.289.587 2H2.036zM7.999 2c1.777 0 3.374.772 4.473 2H3.527A5.985 5.985 0 018 2zM3.527 12A5.985 5.985 0 008 14a5.985 5.985 0 004.473-2H3.527zm-.904-1.334a5.96 5.96 0 01-.587-2h11.927a5.958 5.958 0 01-.587 2H2.623zm5.376-10a7.333 7.333 0 100 14.667A7.333 7.333 0 008 .667z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5260_38722">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default PermissionFullIcon;
