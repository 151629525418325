import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { AccessType } from "@lib/data/schemas/account";
import { SVGIconProps } from "@lib/shared-types";

import Label from "@components/Form/Label";
import { ToggleSelector } from "@components/Form/ToggleSelector";
import PermissionFullIcon from "@components/Icons/PermissionFullIcon";
import PermissionLimitedIcon from "@components/Icons/PermissionLimitedIcon";
import PermissionOwnerIcon from "@components/Icons/PermissionOwnerIcon";

export type OptionType = {
  title: string;
  description: string;
  value: AccessType;
  icon: FC<SVGIconProps>;
};

export const options = [
  {
    label: "Collaborator",
    icon: PermissionLimitedIcon,
    value: "limited",
  },
  {
    label: "Partner",
    icon: PermissionFullIcon,
    value: "elevated",
  },
  {
    label: "Admin",
    icon: PermissionOwnerIcon,
    value: "full",
  },
];

const AccessPermissionFormField: FC = () => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: "accessPermission",
    control,
  });

  return (
    <div className="flex flex-col">
      <Label htmlFor="accessPermission" className="flex items-center mb-1">
        Invitee permissions
      </Label>
      <ToggleSelector onChange={onChange} selected={value} options={options} />
    </div>
  );
};

export default AccessPermissionFormField;
