import { FC } from "react";

import SmallBanner from "@components/Banner/SmallBanner";
import Label from "@components/Form/Label";
import InfoIcon from "@components/Icons/InfoIcon";

import { RateTypeControlledField } from "./RateTypeField";

const HourlyRatesStepFields: FC = () => (
  <div>
    <Label>Hourly rates</Label>
    <div className="flex flex-col space-y-2">
      <RateTypeControlledField name="rateTypes[0]" />
      <RateTypeControlledField name="rateTypes[1]" />
      <RateTypeControlledField name="rateTypes[2]" />
    </div>
    <SmallBanner
      variant="regular"
      className="text-grey-500 mt-2"
      items={[
        {
          Icon: InfoIcon,
          text: "You can add and remove rates later-on from your settings page.",
        },
      ]}
    />
  </div>
);

export default HourlyRatesStepFields;
