import { FC, useState } from "react";
import classNames from "classnames";

import { AccessType } from "@lib/data/schemas/account";
import { SVGIconProps } from "@lib/shared-types";

import Avatar from "@components/Client/ClientAvatar";
import CalendarIcon from "@components/Icons/CalendarIcon";
import GearIcon from "@components/Icons/GearIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import LockIcon from "@components/Icons/LockIcon";
import NoteIcon from "@components/Icons/NoteIcon";
import TeamsIcon from "@components/Icons/TeamsBadgeIcon";
import SubscriptionPlanModal from "@components/Plans/SubscriptionPlanModal";

import AccessPermission from "../AccessPermission";

export interface AboutAccessProps {
  type: AccessType;
  hasUnlimitedMembers?: boolean;
  showPreview?: boolean;
  email?: string;
}

interface BlockquoteOwnerProps {
  quote: string;
  className?: string;
}

export type ContentDataType = {
  icon: FC<SVGIconProps>;
  text: string;
};

export type DataItemType = {
  action: string;
  title: string;
  data: ContentDataType[];
};

interface PreviewMemberProps {
  email: string;
  accessType: AccessType;
}

type DataType = Record<AccessType, DataItemType>;

const full = {
  action: "Invite with full access",
  title: "About admins",
  data: [
    {
      icon: GroupIcon,
      text: "Access to your clients and groups",
    },
    { icon: NoteIcon, text: "Access to your client notes" },
    {
      icon: CalendarIcon,
      text: "Manage appointments and client sessions",
    },
    {
      icon: LockIcon,
      text: "Stripe is not accessible to added users",
    },
    {
      icon: GearIcon,
      text: "Access to manage account settings",
    },
  ],
};
const elevated = {
  action: "Invite with elevated access",
  title: "About partners",
  data: [
    {
      icon: GroupIcon,
      text: "Elevated access to assigned clients only",
    },
    { icon: NoteIcon, text: "Manage notes for assigned clients only" },
    {
      icon: CalendarIcon,
      text: "Unique schedulers and appointments for assigned clients only",
    },
    {
      icon: LockIcon,
      text: "Access to create products, packages and invoices for assigned clients only",
    },
    {
      icon: GearIcon,
      text: "Access to connect calendar, Zoom, email and Zapier to your organization",
    },
  ],
};
const limited = {
  action: "Invite with limited access",
  title: "About collaborators",
  data: [
    {
      icon: GroupIcon,
      text: "Limited access to assigned clients only",
    },
    { icon: NoteIcon, text: "Manage notes for assigned clients only" },
    {
      icon: CalendarIcon,
      text: "Unique schedulers and appointments for assigned clients only",
    },
    {
      icon: LockIcon,
      text: "Access to create products, packages and invoices for assigned clients only",
    },
    {
      icon: GearIcon,
      text: "Access to connect calendar, Zoom, email and Zapier to your organization",
    },
  ],
};
export const data: DataType = { full, elevated, limited };

const BlockquoteOwner: FC<BlockquoteOwnerProps> = ({ className, quote }) => (
  <div className={classNames("flex items-end", className)}>
    <blockquote className="text-sm text-grey-500 border-l-2 pl-2 border-grey-900">
      {quote}
    </blockquote>
  </div>
);

const PreviewMember: FC<PreviewMemberProps> = ({ email, accessType }) => (
  <div className="w-full flex flex-col items-center justify-center flex-1 py-6">
    <Avatar client={{ email }} className="mb-1" />
    <p className="mb-1">{email}</p>
    <AccessPermission type={accessType} isOwner={false} />
  </div>
);

const TeamsTierPlug: FC = () => {
  const [showChangePlanModal, setShowChangePlanModal] =
    useState<boolean>(false);

  return (
    <div className="mt-16 p-4 space-y-2 text-sm bg-white rounded-2xl">
      <TeamsIcon className="h-[18px] w-auto" />
      <div className="leading-tight">
        Practice Teams supports full and elevated members permissions.
      </div>
      <div
        onClick={() => setShowChangePlanModal(true)}
        className="underline font-medium cursor-pointer"
      >
        Upgrade to Teams
      </div>
      <SubscriptionPlanModal
        show={showChangePlanModal}
        toggleShow={setShowChangePlanModal}
        onClose={() => setShowChangePlanModal(false)}
      />
    </div>
  );
};

const AboutAccess: FC<AboutAccessProps> = ({
  type,
  hasUnlimitedMembers,
  showPreview,
  email,
}) => {
  const content = data[type];

  return (
    <div className="bg-grey-950 flex flex-col items-start self-stretch p-4 rounded-2xl">
      {!showPreview && (
        <>
          <p className="text-grey-500 mb-4 text-sm">{content.title}</p>

          <ul className="grid gap-4 w-full mb-8">
            {content.data.map(({ icon: Icon, text }, index: number) => (
              <li
                key={`permission-${type}-${index}`}
                className="mr-2 flex items-center"
              >
                <Icon className="flex-none mr-2 text-action-500" />
                <span className="text-sm font-medium">{text}</span>
              </li>
            ))}
          </ul>
        </>
      )}

      {hasUnlimitedMembers && showPreview && (
        <PreviewMember accessType={type} email={email ?? ""} />
      )}

      {hasUnlimitedMembers ? (
        showPreview ? (
          <>
            <BlockquoteOwner
              quote="We’ll send an invitation as soon as you confirm and guide them through setting themselves up on Practice with 3-easy steps."
              className={showPreview ? "" : "flex-1"}
            />
            <div className="mt-2">
              <p className="text-sm text-black-ink font-medium">Jeremy</p>
              <p className="text-xs text-grey-500">Growth at Practice</p>
            </div>
          </>
        ) : null
      ) : (
        <TeamsTierPlug />
      )}
    </div>
  );
};

export default AboutAccess;
