import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const StoreIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 32 32"
      width="32"
      height="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66675 11.1667C2.66675 6.74847 6.24847 3.16675 10.6667 3.16675H21.3334C25.7517 3.16675 29.3334 6.74847 29.3334 11.1667V20.5001H25.3334V21.8334C25.3334 26.2517 21.7517 29.8334 17.3334 29.8334H10.6667C6.24847 29.8334 2.66675 26.2517 2.66675 21.8334V11.1667ZM22.6667 20.5001H5.33341V21.8334C5.33341 24.7789 7.72123 27.1667 10.6667 27.1667H17.3334C20.2789 27.1667 22.6667 24.7789 22.6667 21.8334V20.5001ZM26.6667 17.8334H24.0001L24.0001 11.9798C24.0001 9.63274 23.0926 7.461 21.5781 5.83893C24.41 5.96678 26.6667 8.30322 26.6667 11.1667V17.8334ZM16.5134 5.83341H15.5784C17.5047 7.56843 18.6667 10.0701 18.6667 12.7702V17.8334H21.3334L21.3334 11.9798C21.3334 9.06009 19.3374 6.52597 16.5134 5.83341ZM9.91124 5.88651C9.3106 5.97169 8.74216 6.15692 8.22333 6.4248C10.8365 7.5464 12.6667 10.1428 12.6667 13.1667V17.8334H16.0001V12.7702C16.0001 9.96678 14.2463 7.4629 11.6117 6.50486L9.91124 5.88651ZM6.01841 8.54999C5.58227 9.32307 5.33341 10.2158 5.33341 11.1667V17.8334H10.0001V13.1667C10.0001 10.8221 8.271 8.88133 6.01841 8.54999Z"
      />
    </svg>
  );
};

export default StoreIcon;
